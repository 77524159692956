import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68474d47"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "paper"
};
const _hoisted_3 = {
  class: "input"
};
const _hoisted_4 = {
  class: "btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_van_field, {
    modelValue: _ctx.content,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.content = $event),
    type: "textarea",
    autosize: "",
    placeholder: "请在此输入正文",
    rows: 10
  }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_van_button, {
    block: "",
    loading: _ctx.loading,
    onClick: _ctx.onSubmit
  }, {
    default: _withCtx(() => [_createTextVNode("提交")]),
    _: 1
  }, 8, ["loading", "onClick"])])]);
}