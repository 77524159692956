import { __decorate } from "tslib";
import { Vue, Options } from 'vue-class-component';
import { Button, Field, Toast } from 'vant';
import { Action } from 'vuex-class';
let FeedVue = class FeedVue extends Vue {
  constructor() {
    super(...arguments);
    this.content = ''; // 反馈的内容
    this.title = ''; // 当前反馈的题目内容
    this.loading = false;
  }
  created() {
    this.title = this.$route.query.q;
  }
  async onSubmit() {
    if (!this.content) {
      Toast({
        message: '请输入反馈的内容'
      });
      return;
    }
    this.loading = true;
    const r = await this.feedQuestion({
      feedbackType: 1,
      feedbackTargetId: parseInt(this.$route.params.id),
      feedbackContent: this.content
    });
    this.loading = false;
    if (r.code === 0) {
      Toast.success('反馈提交成功');
    } else {
      Toast.fail(r.msg || '反馈提交失败');
    }
  }
};
__decorate([Action('Feed/feedQuestion')], FeedVue.prototype, "feedQuestion", void 0);
FeedVue = __decorate([Options({
  components: {
    [Button.name]: Button,
    [Field.name]: Field
  }
})], FeedVue);
export default FeedVue;